import React from 'react'
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Videos extends React.Component {
    render() {
    return (
        <main>
            <section id="gallery" className='gallery-page-one'>
            <div className="container">
                <div className="row">
                    <div className="section-title text-left" style={{margin: "0"}}>
                        <h2><span>Checkout our Nature Country</span></h2>
                    </div>
                    <div className="image-gallery" data-filter-class="gallery-sorter">
                    
                        <div className="mt-30">
                        <OwlCarousel className='owl-theme' loop margin={10} >
                            <div className="single-gallery anim-5-all bedroom interoors drawing masonryImage mix span-12">
                                <iframe height="315" src="https://www.youtube.com/embed/B1SWxZhBey0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{border: "0", width: "100%"}} allowFullScreen=""></iframe>
                            </div>
                            <div className="single-gallery anim-5-all bedroom interoors drawing masonryImage mix span-12">
                                <iframe height="315" src="https://www.youtube.com/embed/8Jv0RhF0qEE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{border: "0", width: "100%"}} allowFullScreen=""></iframe>
                            </div>
                            <div className="single-gallery anim-5-all bedroom interoors drawing masonryImage mix span-12">
                                <iframe height="315" src="https://www.youtube.com/embed/BqNBEdDNFQc" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{border: "0", width: "100%"}} allowFullScreen=""></iframe>
                            </div>
                            <div className="single-gallery anim-5-all bedroom interoors drawing masonryImage mix span-12">
                                <iframe height="315" src="https://www.youtube.com/embed/1pXfgaNWzH8" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{border: "0", width: "100%"}} allowFullScreen=""></iframe>
                            </div>
                            <div className="single-gallery anim-5-all bedroom interoors drawing masonryImage mix span-12">
                                <iframe height="315" src="https://www.youtube.com/embed/8n1B4DNFn90" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" style={{border: "0", width: "100%"}} allowFullScreen=""></iframe>
                            </div>
                            </OwlCarousel>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
</main>
        );
    }
}

export default Videos;