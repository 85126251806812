import React from 'react'
class Footer extends React.Component {
    render() {
        return(
            <footer id="footer-home" className="anim-5-all text-center-sm text-center-xs">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-left">
                <a href="#"><img src="assets/img/resources/sunriseinfralogo.png" alt="" /></a>
                <p className="pt-2 pb-2">Sunrise Infra Projects is founded by group of committed professionals with rich experience and vast knowledge in the real estate sector.</p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-left">
                <h6>Up Coming Projects</h6>
                <ul>
                    <li>&nbsp;</li>
                    <li><a href="Nature Township.php">Nature Township</a></li>
                    <li>&nbsp;</li>
                </ul>

                <h6>On Going Projects</h6>
                <ul>
                    <li>&nbsp;</li>
                    <li><a href="projects.php">Nature County</a></li>
                    <li><a href="naturehome.php">Nature Homes</a></li>
                    <li>&nbsp;</li>
                </ul>

                <h6>Completed Projects</h6>
                <ul>
                    <li>&nbsp;</li>
                    <li><a href="projects.php">Nature County</a></li>
                </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-left">
                <h6>Comapny Support</h6>
                <ul className="footer-links">
                    <li>&nbsp;</li>
                    <li><p className="footP">Our support available to help you 24 hours a day, seven days a week.</p></li>
                    <li><p className="footP"> Wednesday - Monday</p></li>
                    <li><p className="footP"> 9:30 am - 06:30 pm</p></li>
                    <li><p className="footP">Tuesday: Closed</p></li>
                    
                </ul>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 pull-left">
                <h6>Contact Info</h6>
                <ul className="footer-links">
                    <li>&nbsp;</li>
                    <li><p className="footP">Sunrise Infra Projects,</p></li>
                    <li><p className="footP"> 1B, Trendz Jr,Sy No 31 to, 36,</p></li>
                    <li><p className="footP"> Vittal Rao Nagar Rd,</p></li>
                    <li><p className="footP"> Madhapur, Telangana 500081</p></li>
                    <li><p className="footP"><i className="fa fa-phone"></i>  040-42220467,9912345188</p></li>
                    <li><p className="footP"><i className="fa fa-envelope"></i>  contact@sunriseinfra.co.in</p></li>
                </ul>
            </div>
        </div>
        <div className="row">
            <div className="row-outer">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <!-- .footer-copyright --> */}
                    <div className="footer-copyright">
                        <p>Copyright &copy; 2022 All Rights Reserved by Sunrise infra Projects.</p>
                    </div>
                    {/* <!-- .footer-copyright --> */}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="footer-menu">
                        <div className="social-icons">
                            <ul>
                                <li><a style={{margin: "0"}} href="https://www.facebook.com/sunriseinfraprojects" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                <li><a style={{margin: "0"}}  href="https://twitter.com/InfraSunrise" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                <li><a style={{margin: "0"}}  href="https://www.linkedin.com/company/72002905" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                <li><a style={{margin: "0"}}  href="https://www.instagram.com/sunriseinfra/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                <li><a style={{margin: "0"}}  href="https://www.youtube.com/channel/UCzdwGmGqHnzSxUHRt0q1SKg" target="_blank"><i className="fa fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    
</footer>
            );
        }
    }

export default Footer;