import React from 'react'


class Blog extends React.Component {
    render() {
    return (
        <main>
            <section id="blogs" className='blog-container blog-2-col'>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <div className="single-blog-post anim-5-all">
                            <div className="img-holder">
                                <img src="assets/img/blogs/1.png" alt="" />
                                <div className="overlay"><a href="/blogsingle"><i className="fa fa-link"></i></a></div>
                            </div>
                            <div className="post-meta">
                                <div className="date-holder">
                                    <b>09</b> Nov
                                </div>
                                <div className="title-holder">
                                    <h2 className="title">Advantages of buying a property during its ‘New Launch Phase’</h2>
                                    <ul>
                                        <li><a href="/blogsingle">By: Sunrise Infra</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="content">
                                <p>If you are familiar with the real estate market, you may have heard terms such as “pre-launch”, “new launch”, etc. These all refer to recently launched projects. These are general terms used to sell real...</p>
                                <a href="react/blogsingle" className="read-more">Read More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            </main>
        );
    }
}

export default Blog;