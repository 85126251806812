import React from 'react'
import ReactDOM from "react-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

class Images extends React.Component {
    
    render() {
    return (
        <main>
            <section id="gallery" className="gallery-page-one">
            <div className="container">
               
                <div className="row">
                    <div className="section-title text-left" style={{marginTop: "0"}}>
                        <h2><span>Checkout our Nature Country</span></h2>
                    </div>
                    <div className="image-gallery">
                        <OwlCarousel className='owl-theme' loop margin={10} >
                        <div className="single-gallery anim-5-all span-12">
                            <div className="img-holder">
                                <img src="assets/img/gallery/1.jpg" alt="" />
                                <div className="content">
                                    <div className="image-view">
                                        <a className="fancybox" href="assets/img/gallery/1.jpg">
                                            <i className="fa fa-camera"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-content">
                                <img src="assets/img/gallery/1.jpg" alt="" className="item-image" />

                            </div>
                        </div>
                        
                        <div className="single-gallery anim-5-all span-12">
                            <div className="img-holder">
                                <img src="assets/img/gallery/3.jpg" alt="" />
                                <div className="content">
                                    <div className="image-view">
                                        <a className="fancybox" href="assets/img/gallery/3.jpg">
                                            <i className="fa fa-camera"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-content">
                                <img src="assets/img/gallery/3.jpg" alt="" className="item-image" />
                            </div>
                        </div>
                        <div className="single-gallery anim-5-all span-12">
                            <div className="img-holder">
                                <img src="assets/img/gallery/4.jpg" alt="" />
                                <div className="content">
                                    <div className="image-view">
                                        <a className="fancybox" href="assets/img/gallery/4.jpg">
                                            <i className="fa fa-camera"></i>
                                        </a>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="modal-content">
                                <img src="assets/img/gallery/4.jpg" alt="" className="item-image" />

                            </div>
                        </div>
                        <div className="single-gallery anim-5-all  span-12">
                            <div className="img-holder">
                                <img src="assets/img/gallery/5.jpg" alt="" />
                                <div className="content">
                                    <div className="image-view">
                                        <a className="fancybox" href="assets/img/gallery/5.jpg">
                                            <i className="fa fa-camera"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-content">
                                <img src="assets/img/gallery/5.jpg" alt="" className="item-image" />


                            </div>
                        </div>
                        <div className="single-gallery anim-5-all span-12">
                            <div className="img-holder">
                                <img src="assets/img/gallery/6.jpg" alt="" />
                                <div className="content">
                                    <div className="image-view">
                                        <a className="fancybox" href="assets/img/gallery/6.jpg">
                                            <i className="fa fa-camera"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-content">
                                <img src="assets/img/gallery/6.jpg" alt="" className="item-image" />

                            </div>
                        </div>
                        <div className="single-gallery anim-5-all span-12">
                            <div className="img-holder">
                                <img src="assets/img/gallery/7.jpg" alt="" />
                                <div className="content">
                                    <div className="image-view">
                                        <a className="fancybox" href="assets/img/gallery/7.jpg">
                                            <i className="fa fa-camera"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-content">
                                <img src="assets/img/gallery/7.jpg" alt="" className="item-image" />

                            </div>
                        </div>
                        </OwlCarousel>;



                    </div>
                </div>
            </div>
        </section>
</main>
        );
    }
}

export default Images;