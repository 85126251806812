import React from 'react'
import { Link, NavLink } from 'react-router-dom';



class Header extends React.Component {
    render() {
        
        return(
            <header className="header-v2-wrap stricky">
                <section className="hidden-bar anim-5">
    <div className="hidden-bar-closer">
        <button className="btn">
            <i className="fa fa-close"></i>
        </button>
    </div>
    <div className="hidden-bar-wrapper">
        <div className="logo text-center">
            <a href='/'>
                <img src="assets/img/resources/sunriseinfralogo.png" alt="" />
            </a>			
        </div>
        <div className="main-menu text-center">
            <ul className="navigation clearfix">
                <li><a href='/'>Home</a></li>
                <li><NavLink to="/about">About Us</NavLink></li>
                <li className="dropdown">
                    <NavLink to="/projects" className='disabled-link'>Projects</NavLink>
                        <ul className="submenu">
                            <li><NavLink to="/naturecounty">Nature County</NavLink></li>
                            <li><NavLink to="/naturehomes">Nature Homes</NavLink></li>
                        </ul>
                </li>
                <li className="dropdown">
                    <NavLink to="/gallery">Gallery</NavLink>
                        <ul className="submenu">
                            <li><NavLink to="/images">Images</NavLink></li>
                            <li><NavLink to="/videos">Videos</NavLink></li>
                        </ul>
                </li>
                <li><NavLink to="/blog">Blog</NavLink></li>
                            <li><NavLink to="/contact">Contact Us</NavLink></li>
            </ul>
        </div>
    </div>
</section>
<section>
    <section id="top-bar" className="anim-3-all anim-3">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 pull-left call-back">
                    <a href="tel:+9199123 45188"><i className="fa fa-phone"></i> Request Call Back</a>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-6 col-xs-12 pull-right text-right">
                    <ul className="contact-info">
                        <li><a href="mailto:contact@sunriseinfra.co.in"><i className="fa fa-envelope"></i> contact@sunriseinfra.co.in</a></li>
                        <li><a href="https://api.whatsapp.com/send?phone=919912345188" target="_blank"><i className="fa fa-whatsapp"></i> +91 99123 45188</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <header className="header-inner header-v2">
        <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-6  logo anim-5 anim-5-all">
                <a href='/'><img src="assets/img/resources/sunriseinfralogo.png" alt="" /></a>
                </div>
                <nav className="col-lg-9 col-md-9 col-sm-8 col-xs-6  pull-right text-right anim-5 anim-5-all main-menu">
                    <div className="navbar-collapse collapse clearfix">
                        <ul className="navigation clearfix">
                            <li><a href='/'>Home</a></li>
                            <li><NavLink to="/about">About Us</NavLink></li>
                            <li className="dropdown">
                            <NavLink to="/projects" className='disabled-link'>Projects</NavLink>
                                <ul className="submenu">
                                    <li><NavLink to="/naturecounty">Nature County</NavLink></li>
                                    <li><NavLink to="/naturehomes">Nature Homes</NavLink></li>
                                </ul>
                            </li>
                            <li className="dropdown">
                    <NavLink to="/react/gallery" className='disabled-link'>Gallery</NavLink>
                        <ul className="submenu">
                            <li><NavLink to="/images">Images</NavLink></li>
                            <li><NavLink to="/videos">Videos</NavLink></li>
                        </ul>
                </li>
                <li><NavLink to="/blog">Blog</NavLink></li>
                            <li><NavLink to="/contact">Contact Us</NavLink></li>
                        </ul>
                    </div>
                    <div className="navbar-header"> 	
                        <button type="button" className="navbar-toggle hidden-bar-opener">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>   	
                    </div>
                </nav>
            </div>
        </div>
    </header>
    <div className="sidebar-contact">
        <div className="toggle">Contact Us</div>
        <h2>Contact Us</h2>
        <div className="scroll">
            <form>
                <input type="text" name="" placeholder="Name" />
                <input type="email" name="" placeholder="Email" />
                <input type="rel" name="" placeholder="Phone Number" />
                <textarea placeholder="Message here.."></textarea>
                <input type="submit" name="" value="send" />
            </form>
        </div>
    </div>
</section>
            </header>
        );
    }
}
export default Header;