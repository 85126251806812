import React from 'react'
import { Formik, Form, Field, useFormik } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"

const NatureHomeForm = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            PlotNo: '',
            Name: '',
            PhoneNo: ''
        },

        onSubmit: (values, { setSubmitting,resetForm }) => {
            console.log(values, "values")

            toast.success("Form Submitted Successfully! ..Thank You")

            // formik.values.PhoneNo = ""
            // formik.values.Name = ""
            // formik.values.PlotNo = ""
            resetForm({values:""})

            setTimeout(() => {
                // alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
                navigate("/")
            }, 5000);
        },

        validate: values => {

            const errors = {};


            // //for Plot No
            if (!formik.touched.PlotNo && !formik.values.PlotNo) {
                errors.PlotNo = 'PlotNo Required';
            }

            //for Name
            if (!formik.touched.Name && !formik.values.Name) {
                errors.Name = 'Name Required';
            }

            //for PhoneNo
            if (!formik.touched.PhoneNo && !formik.values.PhoneNo) {
                errors.PhoneNo = ' PhoneNo Required';
            }

            //for Message
            // if (!formik.touched.Message && !formik.values.Message) {
            //     errors.Message = 'Required';
            // }

            return errors;
        },


    });


    return (


        <section className="contact-content anim-3-all">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <h2>Please submit your details... we will get back to you as soon as possible</h2>
                        <br />
                        <ToastContainer />
                        <div className="contact-form-wrap">

                            <Formik>
                                {({ isSubmitting }) => (
                                    <Form onSubmit={formik.handleSubmit} autoComplete="off">
                                        {isSubmitting ? <div>Loading...</div> :

                                            <>
                                                <Field required type="text" name="PlotNo" value={formik.values.PlotNo} placeholder="Plot No" onChange={formik.handleChange('PlotNo')} />
                                                {formik.touched.PlotNo && formik.errors.PlotNo ? <p>{formik.errors.PlotNo}</p> : null}

                                                <Field required type="text" name="Name" value={formik.values.Name} placeholder="Name" onChange={formik.handleChange} />
                                                {formik.touched.Name && formik.errors.Name ? <p>{formik.errors.Name}</p> : null}


                                                <Field required type="text" name="PhoneNo" value={formik.values.PhoneNo} placeholder="Mobile No" onChange={formik.handleChange} />
                                                {formik.touched.PhoneNo && formik.errors.PhoneNo ? <p>{formik.errors.PhoneNo}</p> : null}


                                                <Field type="text" component="textarea" rows="4" name="Message" value={formik.values.Message} placeholder="Your Valuable Message" onChange={formik.handleChange} />
                                                {/* <ErrorMessage name="Message" component="div" className="invalid-feedback" /> */}
                                                {/* {formik.touched.Message && formik.errors.Message ? <p>{formik.errors.Message}</p> : null} */}


                                                <button className="button" type="submit" disabled={isSubmitting}>
                                                    Submit
                                                </button>

                                            </>
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>

                </div>

            </div>
        </section>


    )
}

export default NatureHomeForm