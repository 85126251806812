import React from 'react'
import { NavLink } from 'react-router-dom';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Banner from "../components/Banner/Banner";



const Home = () => {

	return (
		<main>


			{/* <!-- slider start --> */}
			<Banner />
			{/* <!-- slider end --> */}
			<section id="property-highlight">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-12 col-sm-12 full-img">
							<div className="img-holder">
								<video style={{ width: "100%" }} height="506" controls>
									<source src="assets/video/1.mp4" type="video/mp4" />
									<source src="video/movie.ogg" type="video/ogg" />
									Your browser does not support the video tag.
								</video>
							</div>
						</div>
						<div className="col-lg-7 col-md-12 col-sm-12 property-highlight-text">
							<div className="section-title">
								<h2><span>Plot Highlights</span></h2>
							</div>
							<div className="row">
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>60’&40′ & 30′ BT Roads</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Ready for Construction</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Children’s Park</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Avenue Plantation</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Avenue Plantation</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Water Harvesting Pits</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Overhead Water Tank</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>100% Vaastu</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Underground Drainage</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
								<div className="col-lg-6 col-md-6 col-sm-6">
									<div className="single-property-highlight">
										<h3>Entire Layout Compound Wall with Entrance Arch</h3>
										<p>Don't wait to Get it Slipped from Your Hand</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section id="room-dimention">
				<div className="container">
					<div className="section-title text-center">
						<span>Current Projects</span>
						<h2><span>Projects</span></h2>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12">
						<div className="property-img position-relative overflow-hidden">
							<NavLink to="/NatureCountry"><img className="w-100" src="assets/img/projects/natury-country.png" alt="" /></NavLink>
						</div>
						<div className="property-content bg-grey p-20">
							<NavLink className="color-secondary mb-5" to="/NatureCountry"><h4 className='mb-0 text-center'>Nature County</h4></NavLink>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12">
						<div className="property-img position-relative overflow-hidden">
							<NavLink to="/NatureHomes"><img className="w-100" src="assets/img/projects/nature-homes.png" alt="" /></NavLink>
						</div>
						<div className="property-content bg-grey p-20">
							<NavLink to="/NatureHomes" className="color-secondary mb-5"><h4 className='mb-0 text-center'>Nature Homes</h4></NavLink>
						</div>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12">
						<div className="property-img position-relative overflow-hidden">
							<img className="w-100" src="assets/img/projects/nature-township.png" alt="" />
						</div>
						<div className="property-content bg-grey p-20">
							<a className="color-secondary mb-5" href="nature-county.html"><h4 className='mb-0 text-center'>Nature Township</h4></a>
						</div>
					</div>
				</div>
			</section>
			<section id="blogs" className="blog-container bg-grey blog-2-col">
				<div className="container">
					<div className="section-title text-center">
						<span>Tips and Tricks</span>
						<h2><span>Latest blogs</span></h2>
					</div>


					<OwlCarousel className='owl-theme' loop margin={10} >
						<div className="col-xs-12">
							<div className="single-blog-post anim-5-all">
								<div className="img-holder">
									<img src="assets/img/blogs/1.png" alt="" />
									<div className="overlay"><a href="blog-single.html"><i className="fa fa-link"></i></a></div>
								</div>
								<div className="post-meta">
									<div className="date-holder">
										<b>09</b> Aug
									</div>
									<div className="title-holder">
										<h2 className="title">Advantages of buying a property during its ‘New Launch Phase’</h2>
										<ul>
											<li><span>By: Sunrise Infra</span></li>
										</ul>
									</div>
								</div>
								<div className="content">
									<p>If you are familiar with the real estate market, you may have heard terms such as “pre-launch”, “new launch”, etc. These all refer to recently launched projects. These are general terms used to sell real..</p>
									<a href="blog-single.html">Read more  <i className="fa fa-angle-double-right"></i></a>
								</div>
							</div>
						</div>
						<div className="col-xs-12">
							<div className="single-blog-post anim-5-all">
								<div className="img-holder">
									<img src="assets/img/blogs/2.jpg" alt="" />
									<div className="overlay"><a href="blog-single.html"><i className="fa fa-link"></i></a></div>
								</div>
								<div className="post-meta">
									<div className="date-holder">
										<b>08</b> Sep
									</div>
									<div className="title-holder">
										<h2 className="title">How to Invest Money in Real Estate</h2>
										<ul>
											<li><span>By: Sunrise Infra</span></li>
										</ul>
									</div>
								</div>
								<div className="content">
									<p>Invest Money in Real Estate? “Real estate cannot be lost or stolen, nor can it be carried away. Purchased with common sense, paid for in full, and managed with reasonable care, it is about the...</p>
									<a href="blog-single.html">Read more  <i className="fa fa-angle-double-right"></i></a>
								</div>
							</div>
						</div>
						<div className="col-xs-12">
							<div className="single-blog-post anim-5-all">
								<div className="img-holder">
									<img src="assets/img/blogs/3.jpg" alt="" />
									<div className="overlay"><a href="blog-single.html"><i className="fa fa-link"></i></a></div>
								</div>
								<div className="post-meta">
									<div className="date-holder">
										<b>09</b> Jan
									</div>
									<div className="title-holder">
										<h2 className="title">The Benefits of Investing in Real Estate</h2>
										<ul>
											<li><span>By: Sunrise Infra</span></li>
										</ul>
									</div>
								</div>
								<div className="content">
									<p>When compared to investing in gold or equity, is Real estate a safe bet? To answer this question, it is important to understand what a real estate investment is. Real estate is a tangible asset...</p>
									<a href="blog-single.html">Read more  <i className="fa fa-angle-double-right"></i></a>
								</div>
							</div>
						</div>

						<div className="col-xs-12">
							<div className="single-blog-post anim-5-all">
								<div className="content pt-30-per">
									<a href="blog.html" className="schedule-visit anim-5">View All Posts <i className="fa fa-arrow-circle-right"></i></a>
								</div>
							</div>
						</div>
					</OwlCarousel >
				</div>
			</section>
			<section id="home-footer-google-map-wrap">
				<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15225.80853023878!2d78.3874771!3d17.4380608!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3046379d63dcdebd!2sSunrise%20Infra%20Projects!5e0!3m2!1sen!2sin!4v1647014898885!5m2!1sen!2sin" height="450" style={{ border: "0", width: "100%" }} allowFullScreen="" loading="lazy"></iframe>
			</section>

		</main>

	);

}
export default Home;