import React, { useEffect, useState } from 'react'
import $ from "jquery";

const NatureCounty = () => {
    const [maplic, setMaplic] = useState(false)

    useEffect(() => {
        console.log(window.location.pathname)

        if (window.location.pathname === "/naturecounty") {
            setMaplic(true)
        }


        $(document).ready(function () {
            window.$('#mapplic1').mapplic({
                source: 'assets/MapData/Map/naturecountry.json',
                sidebar: false,
                height: 550,
                fullscreen: true,
                maxscale: 1.8,
                // alphabetic:true,


                // mapfill: false,
                minimap: true,
                // lightbox: true,
                // fullscreen: true,
                // thumbholder: true,
                // landmark: 'entrance',
                // developer: false,
                // zoommargin: 0,
                // maxscale: 1.8,

                bgcolor: '#333',
                bgcolor2: '#666',
                textcolor: '#aaa',
                headingcolor: '#fff'
            });
        });
        return () => {
            console.log("cleaned up here")
        }
    }, [])


    return (
        <main>
            <section className="single-property">
                <div className="container">
                    <div className="row">

                        <div className="col-md-12 col-lg-12">
                            <div className="mb-30">
                                <div className="row">
                                    <div className="col-md-12 col-lg-8 wow slideInRight animated">
                                        <div className="single-property position-relative">
                                            <span className="bg-secondary text-white z-index-1 px-15 py-5 mr-20">For Sale</span>

                                            <h3 className="color-secondary mt-15">Nature Country</h3>
                                            <span className="address icon-primary f-14 mt-5"><i className="fa fa-map-marker"></i>Raikal, Shadnagar, Telangana.</span>
                                        </div>
                                    </div>
                                    <div className="col-md-12 col-lg-4 wow slideInLeft animated">
                                        <div className="thumbnail-content float-right">
                                            <ul className="nav nav-tabs border-0 float-right navbar-tab-view mt-15 sm-mt-0" role="tablist" style={{ lineHeight: "20" }}>
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"><i className="fa fa-file-image-o" aria-hidden="true"></i></a>
                                                </li>
                                                <li className="nav-item">
                                                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i className="fa fa-map-marker"></i></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {/* <div className="container-fluid wow slideInUp animated">
                    <div className="row d-flow-root">
                        <div className="product-slider">
                            <div className="tab-content">
                                <div className="tab-pane active position-relative" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="service-img">
                                        <img src="assets/MapData/Map/naturecountry.svg" alt="image" />
                                    </div>
                                </div>
                                <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.3225979771246!2d78.17531681487107!3d17.00783918830709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDAwJzI4LjIiTiA3OMKwMTAnMzkuMCJF!5e0!3m2!1sen!2sin!4v1616163151406!5m2!1sen!2sin" height="400px" style={{ border: "0", width: "100%" }} allowFullScreen="" loading="lazy"></iframe>
                                </div>
                                <div className="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.3225979771246!2d78.17531681487107!3d17.00783918830709!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTfCsDAwJzI4LjIiTiA3OMKwMTAnMzkuMCJF!5e0!3m2!1sen!2sin!4v1616163151406!5m2!1sen!2sin" height="400px" style={{ border: "0", width: "100%" }} allowFullScreen="" loading="lazy"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 ">
                            <div className=" map-container">
                                {maplic ? <div id="mapplic1"></div> : null}
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12 col-lg-8">
                            <div className="text-area mt-50 wow slideInLeft animated">
                                <h3 className="color-secondary line-bottom pb-15 mb-20">Description</h3>
                                <p>Sunrise Infra constantly monitors and delivers quality projects with efficient DTCP approved Residential open plots for sale near Hyderabad @ Shadnagar.
                                </p>
                                <p className="mt-15">Fames curabitur aenean maecenas tortor odio nonummy bibendum lorem consequat posuere turpis parturie lorem integer erat iaculis mi velit enim potent penatibus hac pede. Morbi vehicula luctus sem vulputate tortor tincidunt sociis luctus cursus dolor dictum suscipit, auctor odio sociis et euismod lacus.</p>
                            </div>
                            <div className="border-top-1-gray py-30 wow slideInUp animated">
                                <h3 className="color-secondary line-bottom pb-15 mb-20">Property Video</h3>
                                <video style={{ width: "100%" }} height="506" controls>
                                    <source src="assets/video/1.mp4" type="video/mp4" />
                                    <source src="video/movie.ogg" type="video/ogg" />
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                            <div className="border-top-1-gray py-30 wow slideInRight animated">
                                <h3 className="color-secondary line-bottom pb-15 mb-20">Plot Details</h3>
                                <div className="row">
                                    <div className="col-md-12 col-lg-6">
                                        <ul className="list-by-tag">
                                            <li>DTCP approved venture</li>
                                            <li>Best place to Invest</li>
                                            <li>Close proximity to RRR</li>
                                            <li>Near to NH44 Bangalore Highway</li>
                                            <li>Approved Venture</li>
                                        </ul>
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <ul className="list-by-tag hover-secondery-primary">
                                            <li>Gated community developments</li>
                                            <li>Near by Already constructed Villas</li>
                                            <li>Fully Residential area</li>
                                            <li>Spot Registration</li>
                                            <li>Rs.9999/- Per Sq.Yrd</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="border-top-1-gray py-30 wow slideInLeft animated">
                                <h3 className="color-secondary line-bottom pb-15 mb-20">Amenities</h3>
                                <div className="row">
                                    <div className="col-md-12 col-lg-12">
                                        <ul className="single-property-amenities icon-primary my-20">
                                            <li><i className="fa fa-check-square" aria-hidden="true"></i><span>Children’s Park</span></li>
                                            <li><i className="fa fa-check-square" aria-hidden="true"></i><span>Avenue Plantation</span></li>
                                            <li><i className="fa fa-check-square" aria-hidden="true"></i><span>Water Harvesting Pits</span></li>
                                            <li><i className="fa fa-check-square" aria-hidden="true"></i><span>Underground Drainage</span></li>
                                            <li><i className="fa fa-check-square" aria-hidden="true"></i><span>Entire Layout Compound Wall</span></li>
                                            <li><i className="fa fa-check-square" aria-hidden="true"></i><span>100% Vaastu</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="border-top-1-gray py-30 wow slideInDown animated">
                                <h3 className="color-secondary line-bottom pb-15 mb-20">Brochure</h3>
                                <div className="row photos">
                                    <div className="col-sm-4 item"><a target="_blank" href="brochure/2.pdf"><img className="img-fluid" src="assets/img/brochure/2.jpeg" /></a></div>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-12 col-lg-4">
                            <div className="sidebar-widget bg-white mt-50 shadow py-40 px-30 wow slideInUp animated">
                                <div className="single-sidebar">
                                    <h3><span>Other Properties</span></h3>
                                    <ul className="popular-post">
                                        <li>
                                            <div className="img-holder">
                                                <img src="assets/img/projects/9.jpg" alt="" />
                                            </div>
                                            <div className="content">
                                                <h4><a href="/naturehomes">Nature Homes</a></h4>
                                                <span>Shadnagar @ Telangana</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </main>
    );

}

export default NatureCounty;