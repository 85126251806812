import React from 'react'

class Contact extends React.Component {
    render() {
    return (
        <main>
        <section className="contact-content anim-3-all">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                        <div className="contact-form-wrap">
                            <form action="http://world5.commonsupport.com/html/apart-villa/inc/sendemail.php" className="contact-form">
                                <p><input name="name" type="text" placeholder="Name" /></p>
                                <p><input name="email" type="text" placeholder="E-mail" /></p>
                                <p><input name="subject" type="text" placeholder="Subject" /></p>
                                <p><textarea name="message" placeholder="Message"></textarea></p>
                                <p><button type="submit">submit now</button></p>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                        <div className="agent-info style-two text-center">
                            <div className="content">
                                <h3>Corporate Office</h3>
                                <p className="certificate">#1B, Trendz Jr, Sy No 31 to 36,<br />
                                    Vittal Rao Nagar Road, Madhapur,<br /> Hyderabad, Telangana 500081</p>
                                <p><a href="tel:+9199123 45188">Phone : +91 99123 45188</a></p>
                                <p><a href="mailto:contact@sunriseinfra.co.in">Email : contact@sunriseinfra.co.in</a></p>
                            </div>
                            <div style={{textAlign: "center"}} className="social-icons">
                                <ul>
                                    <li><a href="https://www.facebook.com/sunriseinfraprojects" target="_blank"><i className="fa fa-facebook"></i></a></li>
                                    <li><a href="https://twitter.com/InfraSunrise" target="_blank"><i className="fa fa-twitter"></i></a></li>
                                    <li><a href="https://www.linkedin.com/company/72002905" target="_blank"><i className="fa fa-linkedin"></i></a></li>
                                    <li><a href="https://www.instagram.com/sunriseinfra/" target="_blank"><i className="fa fa-instagram"></i></a></li>
                                    <li><a href="https://www.youtube.com/channel/UCzdwGmGqHnzSxUHRt0q1SKg" target="_blank"><i className="fa fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="call-to-action style-two clearfix">
                    <div className="col-lg-9 col-md-8 col-sm-12 col-xs-12">
                        <div className="call-to-action-text">
                            <h2>Interesting in a showing</h2>
                            <p>Sunrise Infra constantly monitors and delivers quality projects with efficient services to provide value and transparency for our customers.</p>
                        </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 text-center">
                        <button  data-toggle="modal" data-target="#contact-agent-modal"><a target="_blank" href="https://api.whatsapp.com/send/?phone=919912345188&text&app_absent=0">shedule a visit</a></button>
                    </div>
                </div>
            </div>
            </div>
        </section>

        <section id="home-footer-google-map-wrap">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15225.80853023878!2d78.3874771!3d17.4380608!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x3046379d63dcdebd!2sSunrise%20Infra%20Projects!5e0!3m2!1sen!2sin!4v1647523736092!5m2!1sen!2sin" height="450" style={{width: "100%"}} allowFullScreen loading="lazy"></iframe>

            <div className="info-window" data-map-name="home-footer-google-map">
                <div className="house-location">
                    <p>Chris Niswandee, Smallsys Inc 795 E Dragram, Tucson AZ 56879 <br /> USA</p>
                </div>
            </div>
            </section>
            </main>
    );
    }
}

export default Contact;