import React, { useEffect } from 'react'
import "./Banner.scss"
import "./Bannerscript.js"


const Banner = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "./Bannerscript.js";
        script.async = true;
        script.type = "text/babel"

        document.body.appendChild(script);
    })
    return (
        <div className="">

            <div className="demo-cont">

                <div className="fnc-slider example-slider">
                    <div className="fnc-slider__slides">

                        <div className="fnc-slide m--blend-green m--active-slide">
                            <div className="fnc-slide__inner">
                                <div className="fnc-slide__mask">
                                    <div className="fnc-slide__mask-inner"  >
                                        {/* style={{ backgroundImage: 'assets/img/blogs/1.png' }} */}
                                    </div>
                                </div>
                                <div className="fnc-slide__content">
                                    <h2 className="fnc-slide__heading">
                                        <div className="fnc-slide__heading-line">
                                            <span>Don't wait to buy a Land,</span>
                                            
                                        </div>
                                        <div className="fnc-slide__heading-line">
                                            <span>Buy a Land and Wait</span>
                                        </div>
                                    </h2>
                                    <button type="button" className="fnc-slide__action-btn">
                                        SUNRISE INFRA
                                        <span data-text="Credits">Projects</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="fnc-slide m--blend-dark">
                            <div className="fnc-slide__inner">
                                <div className="fnc-slide__mask">
                                    <div className="fnc-slide__mask-inner" >
                                        {/* style={{ backgroundImage: 'assets/img/slides/slide1.jpg' }} */}
                                    </div>
                                </div>
                                <div className="fnc-slide__content">
                                    <h2 className="fnc-slide__heading">
                                        <div className="fnc-slide__heading-line">
                                            <span>our own LAND is</span>
                                           
                                        </div>
                                        <div className="fnc-slide__heading-line">
                                            <span> Prestige and Pride too</span>
                                        </div>
                                    </h2>
                                    <button type="button" className="fnc-slide__action-btn">
                                        SUNRISE INFRA
                                        <span data-text="Credits">Projects</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="fnc-slide m--blend-red">
                            <div className="fnc-slide__inner">
                                <div className="fnc-slide__mask">
                                    <div className="fnc-slide__mask-inner">
                                        {/* style={{ backgroundImage: 'assets/img/slides/slide2.jpg' }} */}
                                    </div>
                                </div>
                                <div className="fnc-slide__content">
                                    <h2 className="fnc-slide__heading">
                                        <div className="fnc-slide__heading-line">
                                            <span>Invest in land. That'll</span>
                                            
                                          
                                        </div>
                                        <div className="fnc-slide__heading-line">
                                            <span>enhance your life and wealth</span>
                                        </div>
                                    </h2>
                                    <button type="button" className="fnc-slide__action-btn">
                                        SUNRISE INFRA
                                        <span data-text="Credits">Projects</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="fnc-slide m--blend-blue">
                            <div className="fnc-slide__inner">
                                <div className="fnc-slide__mask">
                                    <div className="fnc-slide__mask-inner" ></div> 
                                    {/* style={{ backgroundImage: 'assets/img/slides/slide3.jpg' }} */}
                                </div>
                                <div className="fnc-slide__content">
                                    <h2 className="fnc-slide__heading">
                                        <div className="fnc-slide__heading-line">
                                            <span>Invest in your FUTURE,</span>
                                            
                                            
                                        </div>
                                        <div className="fnc-slide__heading-line">
                                            <span>Invest in LAND</span>
                                        </div>
                                    </h2>
                                    <button type="button" className="fnc-slide__action-btn">
                                        SUNRISE INFRA
                                        <span data-text="Credits">Projects</span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                    <nav className="fnc-nav">
                        <div className="fnc-nav__bgs">
                            <div className="fnc-nav__bg m--navbg-green m--active-nav-bg"></div>
                            <div className="fnc-nav__bg m--navbg-dark"></div>
                            <div className="fnc-nav__bg m--navbg-red"></div>
                            <div className="fnc-nav__bg m--navbg-blue"></div>
                        </div>
                        <div className="fnc-nav__controls">
                            <button className="fnc-nav__control">
                                Plot A
                                <span className="fnc-nav__control-progress"></span>
                            </button>
                            <button className="fnc-nav__control">
                                Plot B
                                <span className="fnc-nav__control-progress"></span>
                            </button>
                            <button className="fnc-nav__control">
                                Plot C
                                <span className="fnc-nav__control-progress"></span>
                            </button>
                            <button className="fnc-nav__control">
                                Plot D
                                <span className="fnc-nav__control-progress"></span>
                            </button>
                        </div>
                    </nav>
                </div>

                <div className="demo-cont__credits">
                    <div className="demo-cont__credits-close"></div>
                    <h2 className="demo-cont__credits-heading">Made by</h2>
                    <img src="//s3-us-west-2.amazonaws.com/s.cdpn.io/142996/profile/profile-512_5.jpg" alt="" className="demo-cont__credits-img" />
                    <h3 className="demo-cont__credits-name">Nikolay Talanov</h3>
                    <a href="https://codepen.io/suez/" target="_blank" className="demo-cont__credits-link">My codepen</a>
                    <a href="https://twitter.com/NikolayTalanov" target="_blank" className="demo-cont__credits-link">My twitter</a>
                    <h2 className="demo-cont__credits-heading">Based on</h2>
                    <a href="https://dribbble.com/shots/2375246-Fashion-Butique-slider-animation" target="_blank" className="demo-cont__credits-link">Concept by Kreativa Studio</a>
                    <h4 className="demo-cont__credits-blend">Global Blend Mode</h4>
                    <div className="colorful-switch">
                        <input type="checkbox" className="colorful-switch__checkbox js-activate-global-blending" id="colorful-switch-cb" />
                        <label htmlFor="" className="colorful-switch__label" for="colorful-switch-cb">
                            <span className="colorful-switch__bg"></span>
                            <span className="colorful-switch__dot"></span>
                            <span className="colorful-switch__on">
                                <span className="colorful-switch__on__inner"></span>
                            </span>
                            <span className="colorful-switch__off"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner