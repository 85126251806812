import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    staticData: 
        {
            afewwordsaboutcompany: "A Few Words About Our Company"
        }
    
}


const staticDataSlice = createSlice({
    name: "staticData",
    initialState,
    reducers: {
        addStaticData: (state, { payload }) => {
            state.staticData = payload
        }
    }


})


export const { addStaticData } = staticDataSlice.actions;
export const getAllStaticData = (state) => state.staticData.staticData;
export default staticDataSlice.reducer;
