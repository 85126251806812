import React from 'react';
import { useSelector } from 'react-redux';
import { getAllStaticData } from '../features/staticdata/staticdataSlice';


const About =()=> {

	const StaticData = useSelector(getAllStaticData)
	console.log(StaticData)
    
    return (
        <main>
            <section id="about-page-content">
			<div className="container">
				<div className="row">
					<div className="col-lg-6 col-md-6 about-page-text">
						<div className="section-title">
							<span>Best Realestate Company in Hyderabad</span>
								<h2><span>{StaticData.afewwordsaboutcompany}</span></h2>				
						</div>
						<p>The company has successfully developed & delivered residential properties to the satisfied customers spread all over India and abroad. The company has a huge clientele from different walks of life, while the majority being from the blue chip companies like Infosys, Wipro, Satyam, HCL, HP, IBM, Intel, Cisco etc., to name a few. Needless to say that the quality, maintenance and post-sales support of the projects developed by the company have attracted very high profile customers to invest in their projects. Thousands of happy clients are a testament to the reputation the company has earned today.</p>
						<p>The first step was to invest in a management team driven by values such as professional excellence, customer centricity, trust and transparency. Technology, innovation, process discipline and choosing the right partners came next.</p>
						<p>The Company has a well-trained team in each sphere of its business operations, be it engineering, architecture, layout development, construction, marketing & sales. In the process of growth and change, the company has created a brand name that stands for reliability, high professional standards and long-lasting customer relationships.</p>
					</div>
					<div className="col-lg-6 col-md-6 about-page-image text-right">
						<img src="assets/img/about-page/1.png" alt="" />
					</div>
				</div>
			</div>
		</section>
        <section className="about-agent anim-3-all">
			<div className="container">
				<div className="row">
					<div className="col-md-5">
						<div className="img-holder">
							<img src="assets/img/about-agent/1.jpg" alt="" />
							<div className="social-icons">
								<ul>
									<li><a href="https://www.facebook.com/sunriseinfraprojects" target="_blank"><i className="fa fa-facebook"></i></a></li>
									<li><a href="https://twitter.com/InfraSunrise" target="_blank"><i className="fa fa-twitter"></i></a></li>
									<li><a href="https://www.linkedin.com/company/72002905" target="_blank"><i className="fa fa-linkedin"></i></a></li>
									<li><a href="https://www.instagram.com/sunriseinfra/" target="_blank"><i className="fa fa-instagram"></i></a></li>
									<li><a href="https://www.youtube.com/channel/UCzdwGmGqHnzSxUHRt0q1SKg" target="_blank"><i className="fa fa-youtube"></i></a></li>
								</ul>
							</div>
						</div>

					</div>
					<div className="col-md-7">
						<div className="agent-info-text">
							<div className="section-title">
								<h2><span>Anji Reddy P</span></h2>
								<span>Founder, Managing Director</span>
							</div>
							<p>The youngest and the most dynamic Managing Directors, Mr. Anji Reddy has been playing a vital role in the planning and execution of the ventures. Being a youngster, he has his own innovative approach towards the projects and all endeavors have been quite successful. Playing an important role in all the ventures, He is the young promising face ofSunrise Infra Projects.</p>
						</div>
					</div>
				</div>
			</div>
		</section>
        </main>
    );
    
}

export default About;