import Home from './pages/home';
import About from './pages/About';
import NatureCounty from './pages/NatureCounty';
import NatureHomes from './pages/NatureHomes';
import Images from './pages/Images';
import Videos from './pages/Videos';
import Blog from './pages/Blog';
import BlogSingle from './pages/BlogSingle';
import Contact from './pages/Contact';

import Header from './components/Header';
import Footer from './components/Footer';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import './App.css';
import NatureHomeForm from './pages/NatureHomeForm';

function App() {
  
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home/>} />
          <Route path='/about' element={<About/>} />
          <Route path='/naturecounty' element={<NatureCounty/>} />
          <Route path='/naturehomes' element={<NatureHomes/>} />
          <Route path='/images' element={<Images/>} />
          <Route path='/videos' element={<Videos/>} />
          <Route path='/blog' element={<Blog/>} />
          <Route path='/blogsingle' element={<BlogSingle/>} />
          <Route path='/contact' element={<Contact/>} />
          <Route path='/natureHomeForm' element={<NatureHomeForm />} />

          

        </Routes>
        <Footer />
        </Router>
    </div>
  );
}

export default App;
